<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Champion Registration</title>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />
    <style>
      .upload-card {
        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.3s ease;
      }
      .upload-card:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
      .upload-card input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
      .loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.spinner-container i {
  color: #6c757d; /* Bootstrap secondary color */
}

.spinner-container p {
  margin: 0;
  color: #6c757d;
  font-weight: 500;
}
      .upload-card .upload-icon {
        font-size: 3rem;
        color: #6c757d; /* Changed color to a more professional grey */
      }
      .upload-card img.preview-image {
        max-width: 100%;
        max-height: 150px;
        object-fit: cover;
        border-radius: 0.25rem;
      }
      .upload-card .btn {
        margin-top: 1rem;
      }
      .custom-select {
        z-index: 1050; /* Ensure dropdown is above other elements */
      }
    </style>
  </head>

  <body class="bg-light">
    <!-- Header -->
    <nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
      <div class="container">
        <a class="navbar-brand" href="/">
          <img src="../../assets/images/logo.svg" alt="Logo" style="width: 10rem" />
        </a>
        <h1 class="navbar-text text-dark fw-bold mb-0">Super Champion Registration</h1>
      </div>
    </nav>

    <!-- Loading Spinner -->
    <div *ngIf="loading" class="loading-overlay">
      <div class="spinner-wrapper">
        <div class="spinner-border text-dark" role="status"></div>
        <p class="mt-2">Loading...</p>
      </div>
    </div>

    <!-- Main Content -->
    <div class="container py-5">
      <form [formGroup]="championForm" (ngSubmit)="onSubmit()" class="form" novalidate>
        <mat-accordion class="w-100" multi>
          <div class="row g-4">

            <!-- Document Details Panel -->
            <div class="col-md-6">
              <mat-expansion-panel [expanded]="true" class="shadow-sm rounded">
                <mat-expansion-panel-header class="bg-secondary bg-opacity-10">
                  <mat-panel-title class="text-dark fw-bold">
                    <i class="fas fa-file-alt me-2"></i>Document Details*
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="p-3">
                  <!-- PAN Input -->
                  <mat-form-field appearance="outline" class="w-100 mb-3">
                    <mat-label>PAN Number</mat-label>
                    <input matInput placeholder="Enter PAN" formControlName="panNo" [readonly]="isPanVerified" required />
                    <mat-icon matSuffix>credit_card</mat-icon>
                    <mat-error *ngIf="championForm.get('panNo').hasError('required')">PAN No. is required</mat-error>
                    <mat-error *ngIf="championForm.get('panNo').hasError('pattern')">Enter valid PAN No.</mat-error>
                  </mat-form-field>

                  <!-- Aadhar Input -->
                  <mat-form-field appearance="outline" class="w-100 mb-3">
                    <mat-label>Aadhar Number</mat-label>
                    <input matInput type="number" placeholder="Enter Aadhar" formControlName="aadharNo" [readonly]="isAadharVerified" maxlength="12" required />
                    <mat-icon matSuffix>badge</mat-icon>
                    <mat-error *ngIf="championForm.get('aadharNo').hasError('required')">Aadhar No. is required</mat-error>
                    <mat-error *ngIf="championForm.get('aadharNo').hasError('pattern')">Invalid Aadhar No.</mat-error>
                  </mat-form-field>

                  <!-- Bank Details -->
                  <div class="bank-details-section p-3 bg-light rounded mb-3">
                    <h5 class="text-dark mb-3">Bank Account Details</h5>
                    
                    <mat-form-field appearance="outline" class="w-100 mb-3">
                      <mat-label>Account Number</mat-label>
                      <input matInput type="number" placeholder="Enter Account Number" formControlName="bankAccountNo" [readonly]="isBankVerified" required />
                      <mat-icon matSuffix>account_balance</mat-icon>
                      <mat-error *ngIf="championForm.get('bankAccountNo').hasError('required')">Account No. is required</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100 mb-3">
                      <mat-label>IFSC Code</mat-label>
                      <input matInput placeholder="Enter IFSC" formControlName="bankIFSCCode" [readonly]="isBankVerified" maxlength="11" required />
                      <mat-icon matSuffix>qr_code</mat-icon>
                      <mat-error *ngIf="championForm.get('bankIFSCCode').hasError('required')">IFSC Code is required</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100 mb-3">
                      <mat-label>Account Holder Name</mat-label>
                      <input matInput placeholder="Enter Account Name" formControlName="bankAccountName" required />
                      <mat-icon matSuffix>person</mat-icon>
                      <mat-error *ngIf="championForm.get('bankAccountName').hasError('required')">Account Name is required</mat-error>
                    </mat-form-field>

                    <div class="row">
                      <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Bank Name</mat-label>
                          <input matInput placeholder="Enter Bank Name" formControlName="bankName" required />
                          <mat-icon matSuffix>account_balance</mat-icon>
                          <mat-error *ngIf="championForm.get('bankName').hasError('required')">Bank Name is required</mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Branch Name</mat-label>
                          <input matInput placeholder="Enter Branch" formControlName="bankBranch" required />
                          <mat-icon matSuffix>location_on</mat-icon>
                          <mat-error *ngIf="championForm.get('bankBranch').hasError('required')">Branch Name is required</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>

            <!-- Document Uploads Panel -->
            <div class="col-md-6">
              <mat-expansion-panel [expanded]="true" class="shadow-sm rounded">
                <mat-expansion-panel-header class="bg-secondary bg-opacity-10">
                  <mat-panel-title class="text-dark fw-bold">
                    <i class="fas fa-upload me-2"></i>Document Uploads*
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="p-4">
                  <div class="row g-4">
                    <!-- Profile Photo Upload -->
                    <div class="col-md-6">
                      <div class="upload-card text-center p-3 border rounded hover-shadow">
                        <div class="upload-icon mb-2">
                          <img *ngIf="profilePhoto" [src]="profilePhoto" alt="Profile" class="preview-image rounded" />
                          <i *ngIf="!profilePhoto" class="fas fa-user-circle fa-3x text-dark"></i>
                        </div>
                        <label class="upload-label">
                          <span class="btn btn-outline-secondary btn-sm">
                            <i class="fas fa-upload me-1"></i>Profile Photo
                          </span>
                          <input type="file" class="d-none" accept=".jpg,.jpeg,.png" (change)="selectProfilePhoto($event)" />
                        </label>
                      </div>
                    </div>

                    <!-- PAN Upload -->
                    <div class="col-md-6">
                      <div class="upload-card text-center p-3 border rounded hover-shadow">
                        <div class="upload-icon mb-2">
                          <img *ngIf="panPhoto" [src]="panPhoto" alt="PAN" class="preview-image rounded" />
                          <i *ngIf="!panPhoto" class="fas fa-id-card fa-3x text-dark"></i>
                        </div>
                        <label class="upload-label">
                          <span class="btn btn-outline-secondary btn-sm">
                            <i class="fas fa-upload me-1"></i>PAN Card
                          </span>
                          <input type="file" class="d-none" accept=".jpg,.jpeg,.png,.pdf" (change)="selectPanPhoto($event)" />
                        </label>
                      </div>
                    </div>

                    <!-- Aadhar Upload -->
                    <div class="col-md-6">
                      <div class="upload-card text-center p-3 border rounded hover-shadow">
                        <div class="upload-icon mb-2">
                          <img *ngIf="aadharPhoto" [src]="aadharPhoto" alt="Aadhar" class="preview-image rounded" />
                          <i *ngIf="!aadharPhoto" class="fas fa-address-card fa-3x text-dark"></i>
                        </div>
                        <label class="upload-label">
                          <span class="btn btn-outline-secondary btn-sm">
                            <i class="fas fa-upload me-1"></i>Aadhar Card
                          </span>
                          <input type="file" class="d-none" accept=".jpg,.jpeg,.png,.pdf" (change)="selectAadharPhoto($event)" />
                        </label>
                      </div>
                    </div>

                    <!-- Bank Cheque Upload -->
                    <div class="col-md-6">
                      <div class="upload-card text-center p-3 border rounded hover-shadow">
                        <div class="upload-icon mb-2">
                          <img *ngIf="bankChequePhoto" [src]="bankChequePhoto" alt="Bank Cheque" class="preview-image rounded" />
                          <i *ngIf="!bankChequePhoto" class="fas fa-money-check fa-3x text-dark"></i>
                        </div>
                        <label class="upload-label">
                          <span class="btn btn-outline-secondary btn-sm">
                            <i class="fas fa-upload me-1"></i>Bank Cheque
                          </span>
                          <input type="file" class="d-none" accept=".jpg,.jpeg,.png,.pdf" (change)="selectBankChequePhoto($event)" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>

            <!-- Personnel Details Panel -->
            <div class="col-12">
              <mat-expansion-panel [expanded]="true" class="shadow-sm rounded">
                <mat-expansion-panel-header class="bg-secondary bg-opacity-10">
                  <mat-panel-title class="text-dark fw-bold">
                    <i class="fas fa-user me-2"></i>Personnel Details*
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="p-4">
                  <div class="row g-4">
                    <!-- Name Fields -->
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>First Name</mat-label>
                        <input matInput placeholder="Enter First Name" formControlName="firstName" required />
                        <mat-icon matSuffix>person</mat-icon>
                        <mat-error *ngIf="championForm.get('firstName').hasError('required')">First Name is required</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Enter Last Name" formControlName="lastName" required />
                        <mat-icon matSuffix>person</mat-icon>
                        <mat-error *ngIf="championForm.get('lastName').hasError('required')">Last Name is required</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Father/Husband Name</mat-label>
                        <input matInput placeholder="Enter Father/Husband Name" formControlName="fatherHusbandName" required />
                        <mat-error *ngIf="championForm.get('fatherHusbandName').hasError('required')">Father/Husband Name is required</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-6">
                      <label class="form-label text-muted mb-2">Relation*</label>
                      <mat-radio-group formControlName="fatherHusbandRelation" class="d-flex gap-4">
                        <mat-radio-button value="F" color="primary">
                          Father
                        </mat-radio-button>
                        <mat-radio-button value="H" color="primary">
                          Husband
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <!-- Gender Selection -->
                    <div class="col-12">
                      <label class="form-label text-muted mb-2">Gender*</label>
                      <mat-radio-group formControlName="gender" class="d-flex gap-4">
                        <mat-radio-button value="M" color="primary">
                          <i class="fas fa-male me-1"></i>Male
                        </mat-radio-button>
                        <mat-radio-button value="F" color="primary">
                          <i class="fas fa-female me-1"></i>Female
                        </mat-radio-button>
                        <mat-radio-button value="O" color="primary">
                          <i class="fas fa-user me-1"></i>Other
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Mobile Number</mat-label>
                        <input matInput placeholder="Enter Mobile" formControlName="mobile" [readonly]="isMobileVerified" required maxlength="10" />
                        <mat-icon matSuffix>phone</mat-icon>
                        <mat-error *ngIf="cf.mobile.errors?.required">Mobile No. is required</mat-error>
                        <mat-error *ngIf="cf.mobile.errors?.pattern">Only numbers allowed</mat-error>
                      </mat-form-field>
                      <div class="float-right">
                        <b class="btn btn-danger py-0" *ngIf="!isMobileVerified" (click)="sendOTP()">
                          Send Mobile OTP
                        </b>
                      </div>

                      <div class="form-field">
                        <div *ngIf="mobileOtpSent">
                          <mat-form-field class="full-width">
                            <input 
                              matInput
                              placeholder="Enter OTP"
                              maxlength="4"
                              type="number"
                              formControlName="otp"
                              required
                            />
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="float-right">
                        <b 
                          class="btn btn-info py-0"
                          *ngIf="mobileOtpSent"
                          (click)="verifyOTP()"
                        >
                          Verify Mobile
                        </b>
                      </div>

                      <div class="float-right">
                        <div *ngIf="mobileVerMsg" class="verification-message">
                          <mat-icon style="color: green">check_circle</mat-icon>{{ mobileVerMsg }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Email Address</mat-label>
                        <input matInput placeholder="Enter Email" formControlName="email" [readonly]="isMailVerified" required />
                        <mat-icon matSuffix>email</mat-icon>
                        <mat-error *ngIf="cf.email.errors?.required">Email is required</mat-error>
                        <mat-error *ngIf="cf.email.errors?.email">Enter valid email</mat-error>
                      </mat-form-field>
                      <div class="float-right">
                        <b class="btn btn-danger py-0" *ngIf="!isMailVerified" (click)="sendMailOTP()">
                          Send Email OTP
                        </b>
                      </div>
                      <div class="form-field">
                        <div *ngIf="mailOtpSent">
                          <mat-form-field class="full-width">
                            <input matInput placeholder="Enter OTP" maxlength="4" type="number" formControlName="mailOtp" required />
                          </mat-form-field>
                          <b class="btn btn-info py-0" (click)="verifyMailOTP()">
                            Verify Mail
                          </b>
                        </div>

                      </div>
                      <div class="float-right">
                        <div *ngIf="mailVerMsg" class="verification-message">
                          <mat-icon style="color: green">check_circle</mat-icon>{{ mailVerMsg }}
                        </div>
                      </div>
                    </div>
                    
                    <!-- Address Fields -->
                    <div class="col-12">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Address</mat-label>
                        <textarea matInput placeholder="Enter Address" formControlName="addressLine1" required rows="3"></textarea>
                        <mat-icon matSuffix>home</mat-icon>
                        <mat-error *ngIf="championForm.get('addressLine1').hasError('required')">Address is required</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-6">
                      <angular2-multiselect
                      [data]="cities"
                      [settings]="citySettings"
                      (onSelect)="onCitySelect($event)"
                      (onDeSelect)="onCityDeSelect($event)"
                      formControlName="selectedCity"
                    >
                      </angular2-multiselect>
                    </div>

                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Pincode</mat-label>
                        <input matInput inputmode="numeric" placeholder="Enter Pincode" formControlName="pincode" maxlength="6" required />
                        <mat-icon matSuffix>location_on</mat-icon>
                        <mat-error *ngIf="championForm.get('pincode').hasError('required')">Pincode is required</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="col-12">
              <mat-expansion-panel [expanded]="false" class="shadow-sm rounded">
                <mat-expansion-panel-header class="bg-secondary bg-opacity-10">
                  <mat-panel-title class="text-dark fw-bold">
                    <i class="fas fa-user-friends me-2"></i>Referral Details
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="p-4">
                  <div class="row g-4">
                    <!-- Referral Code -->
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Referral Code</mat-label>
                        <input matInput placeholder="Referral User ID" formControlName="referralId" />
                        <mat-icon matSuffix>code</mat-icon>
                      </mat-form-field>
                    </div>                 
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="col-12">
              <mat-expansion-panel [expanded]="false" class="shadow-sm rounded">
                <mat-expansion-panel-header class="bg-secondary bg-opacity-10">
                  <mat-panel-title class="text-dark fw-bold">
                    <i class="fas fa-file-contract me-2"></i>Terms and Conditions
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="p-4">
                  <div class="terms-container bg-light p-4 rounded">
                    <h4 class="text-dark mb-4">Terms and Conditions for Champions</h4>
                    <div class="terms-content">
                      <p *ngFor="let term of termsAndConditions" class="mb-3">
                        {{ term.tnc }}
                      </p>
                    </div>
                  </div>
                  <mat-checkbox formControlName="agreedToTerms" color="primary" class="mt-4">
                    <span class="text-muted">I have read and agree to the Terms and Conditions</span>
                  </mat-checkbox>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="col-12">
              <div class="alert alert-warning">
                <i class="fas fa-exclamation-triangle me-2"></i>
                <strong>Important Notice:</strong> Please review all details carefully. After payment, you will not be able to edit or view these details.
              </div>
            </div>
            <!-- Payment Panel -->
            <div class="col-12">
              <mat-expansion-panel [expanded]="false" class="shadow-sm rounded">
                <mat-expansion-panel-header class="bg-secondary bg-opacity-10">
                  <mat-panel-title class="text-dark fw-bold">
                    <i class="fas fa-credit-card me-2"></i>Payment Details*
                  </mat-panel-title>
                </mat-expansion-panel-header>
            
                <div class="p-4">
                  <div class="payment-container">
                    <!-- Payment Method Selection -->
                    <div class="payment-method-selection mb-4">
                      <label class="form-label text-muted mb-2">Select Payment Method</label>
                      <mat-radio-group [(ngModel)]="paymentMethod" (change)="onPaymentMethodChange($event.value)" class="d-flex gap-4">
                        <mat-radio-button value="online" color="primary">
                          <i class="fas fa-globe me-1"></i>Pay Online
                        </mat-radio-button>
                        <mat-radio-button value="cheque" color="primary">
                          <i class="fas fa-money-check me-1"></i>Pay by Cheque
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
            
                    <!-- Online Payment Section -->
                    <div *ngIf="paymentMethod === 'online'" class="online-payment-section">
                      <div *ngIf="showPayment && !loading" class="text-center p-4 bg-light rounded">
                        <h3 class="text-dark mb-3">Package Amount: ₹{{ paymentData?.amount }}</h3>
                        <button (click)="submitPayment()" class="btn btn-secondary btn-lg" [disabled]="loading">
                          <span *ngIf="!loading">
                            <i class="fas fa-lock me-2"></i>Pay Securely Now
                          </span>
                          <span *ngIf="loading" class="d-flex align-items-center justify-content-center">
                            <i class="fas fa-spinner fa-spin me-2"></i>Processing...
                          </span>
                        </button>
                      </div>
                    </div>
            
                    <!-- Cheque Payment Section -->
                    <div *ngIf="paymentMethod === 'cheque'" class="cheque-upload-section p-4 bg-light rounded">
                      <div class="mb-4">
                        <label class="form-label">Upload Cheque Image*</label>
                        <div class="custom-file-upload">
                          <input type="file" class="form-control" accept="image/*" (change)="onChequeImageUpload($event)" [disabled]="loading" />
                          <small class="form-text text-muted mt-2">
                            <i class="fas fa-info-circle me-1"></i>Please upload a clear image of your cheque
                          </small>
                        </div>
                      </div>
                      <!-- Cheque Upload Success Message -->
                      <div *ngIf="chequeImage" class="alert alert-success mt-3">
                        <i class="fas fa-check-circle me-2"></i>Cheque image successfully uploaded
                      </div>
                    </div>
            
                    <!-- Online Payment Success Message -->
                    <div *ngIf="!showPayment && !loading && paymentMethod === 'online'" class="success-message text-center p-4 bg-light rounded">
                      <div class="success-icon mb-3">
                        <i class="fas fa-check-circle fa-3x text-success"></i>
                      </div>
                      <h4 class="text-success mb-0">Payment Successfully Captured</h4>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>

            
          </div>
        </mat-accordion>

        <!-- Submit Button -->
        <div class="text-center mt-5">
          <button type="submit" 
                  class="btn btn-secondary btn-lg px-5" 
                  [disabled]="(paymentMethod === 'online' && showPayment) || 
                             (paymentMethod === 'cheque' && !chequeImage) ||
                             loading">
            <span *ngIf="!loading">
              <i class="fas fa-paper-plane me-2"></i>Submit Registration
            </span>
            <div *ngIf="loading" class="loading-overlay">
              <div class="spinner-container">
                <i class="fas fa-spinner fa-spin fa-3x"></i>
                <p class="mt-2">Processing...</p>
              </div>
            </div>
          </button>
        </div>
      </form>
    </div>
  </body>
</html>
