import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ChangeDetectorRef, Component, NgZone } from "@angular/core";
import { AlertService } from "../shared/services/alert.service";
import { CommonService } from "../shared/services/common.service";
import { Router } from "@angular/router";
import { City } from "../modules/support/interfaces/city-autocomplete.interface";
import { ChampionPackageType } from "../modules/support/interfaces/champion-subscription-package.interface";
import { catchError, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SupportService } from "../shared/services/support.service";
import { throwError } from "rxjs";
declare var Razorpay: any;

@Component({
  selector: "superchampion-reg",
  templateUrl: "./superchampion-reg.component.html",
  styleUrls: ["./superchampion-reg.component.css"],
})
export class superchampionRegComponent {
  championForm: FormGroup;
  passHide = true;
  loading = false;
  panPhoto: any;
  
  aadharPhoto: any;
  bankChequePhoto: any;
  profilePhoto: any;
  //pan verify
  isPanVerified = false;
  panVerMsg: string;
  //BANK verify
  isBankVerified = false;
  BankVerMsg: string;
  citySettings: any = {};
  cities: City[] = [];
  termsAndConditions: any[] = [];
  championSubscriptionPackages: ChampionPackageType[] = [];
  championPackageTypeSettings: any = {};
  states: any;
  champions: any;
  tempUserToken: any;
  //adhar verify
  aadharOtpSent = false;
  isAadharVerified = false;
  aadharVerMsg: string;
  aadharRequestid: string;
  aadharTransId: string;
  //for mobile otp
  mobileOtpSent = false;
  isMobileVerified = false;
  mobileVerMsg: string;
  generatedOTP: string;

  //for mail otp
  mailOtpSent = false;
  isMailVerified = false;
  mailVerMsg: string;
  generatedMailOTP: string;

  //payment
  showPayment: boolean = true;
  paymentData: any;
  paymentMethod: 'online' | 'cheque' = 'online';
  chequeImage: string | null = null;
  showChequeUpload: boolean = false;
  validityPeriod: number;
  validityPeriodType: string; // 'D' for days, 'M' for months, 'Y' for years
  packageTitle: string;
  constructor(
    private commonService: CommonService,
    private alertService: AlertService,
    private supportService: SupportService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private http: HttpClient,
    
    public fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.commonService.getCities().subscribe((data: any) => {
      this.cities = data.cities;
    });

    this.commonService.getStates().subscribe((data: any) => {
      this.states = data.states;
    });
    
    this.commonService
      .tempAuth()
      .pipe(
        switchMap((data: any) => {
          this.tempUserToken = data.userToken;
          localStorage.setItem("userToken", this.tempUserToken);
          this.fetchTermsAndConditions();
          console.log(this.tempUserToken);
          
          return this.commonService.championSubscriptionPackageRequest(
            this.tempUserToken,
            "/co-trans/getChampionSubscriptionPackages"
          );
        }),
        catchError((error) => {
          if (error.status === 401) {
            // Handle token refresh or re-authentication
            console.log("Session expired. Please login again.");
          }
          return throwError(error);
        })
      )
      .subscribe((data: any) => {
        this.championSubscriptionPackages = data.championSubscriptionPackages;
        this.paymentData = {
          id: 1,
          amount: this.championSubscriptionPackages[1]["packageCharges"],
        };
      });

    this.championForm = this.fb.group({
      firstName: ["",
         Validators.required
        ],
      lastName: ["", 
        Validators.required
      ],
      fatherHusbandName: ["",
         Validators.required
        ],
      fatherHusbandRelation: ["F",
         Validators.required
        ],
      gender: ["",
         Validators.required
        ],
      mobile: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9-]*$"),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      otp: [""],
      mailOtp: [""],
      email: ["",
         [Validators.required, Validators.email]
        ],
      panNo: [
      "" ,  
        [Validators.pattern("^[A-Z]{5}[0-9]{4}[A-Z]{1}")],
      ],
      chequeImage: [null],
      paymentMethod: ['online'], // default to online payment
     
      
      aadharNo: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$"),
        ],
      ],
      aadharOtp: [""],
      bankAccountNo: [
        { value: "",
           disabled: this.isBankVerified },
        Validators.required,
      ],
      bankName: [{ value: "", disabled: this.isBankVerified }],
      bankBranch: [""],
      bankIFSCCode: [
        { value: "", disabled: this.isBankVerified },
        Validators.required,
      ],
      state: [""],
      pincode: [""],
      addressLine1: ["",
         Validators.required
        ],
      addressLine2: [""],
      countryId: [null],
      countryName: [null],
      stateId: [null],
      stateName: [null],
      cityId: [null,
         [Validators.required]
        ],
      cityName: [null],
      gstType: [""],
      gstNo: [""],
      startDate: [""],
      endDate: [""],
      selectedGSTType: [null],
      profilePhotoData: ["",
         [Validators.required]
        ],
      panPhotoData: ["",
         [Validators.required]
        ],
      aadharPhotoData: ["",
         [Validators.required]
        ],
      bankCancelChqPhotoData: ["",
         [Validators.required]
        ],
      referralId: [""],
      bankAccountName: [""],
      selectedCity: [""],
      paymentStatus: [""],
      selectedChampionPackageType: [""],
      orderId: [""],
      paymentId: [""],
      
      paymentAmount: [""],
      championSubscriptionPackageId: [""],
      championTypeId: ["306eac7c4044d11cc8e58b014f7dfdc0"],
    });
    this.championForm.get('chequeImage')?.valueChanges.subscribe(value => {
      this.chequeImage = value;
    });
    this.citySettings = {
      singleSelection: true,
      lazyLoading: true,
      text: "City*",
      primaryKey: "cityId",
      labelKey: "cityName",
      enableSearchFilter: true,
      showCheckbox: true,
      enableFilterSelectAll: false,
      searchBy: ["cityName", "cityCode"],
      maxHeight: 200,
      classes: "custome-multi-select",
    };

    // Following setting for the Champion Subscription Package Type Autocomplete select
    this.championPackageTypeSettings = {
      singleSelection: true,
      lazyLoading: true,
      text: "Select Champion Subscription Package Type",
      primaryKey: "championSubscriptionPackageId",
      labelKey: "packageTitle",
      enableSearchFilter: true,
      showCheckbox: true,
      enableFilterSelectAll: false,
      searchBy: ["packageTitle"],
      maxHeight: 200,
      classes: "custome-multi-select",
      selectedItems: [this.championSubscriptionPackages[1]],
    };
  }

  // convenience getter for easy access to form fields
  get cf() {
    return this.championForm.controls;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  fetchTermsAndConditions() {
    this.supportService
      .backendAPICall("getTermsAndConditionsList", { tnc_for: "5" })
      .subscribe(
        (result: any) => {
          if (result.responseStatus === "Ok") {
            this.termsAndConditions = result.tncs
              .filter((tnc) => tnc.tncFor === "5")
              .map((tnc) => ({
                ...tnc,
                tnc: this.stripHtmlTags(tnc.tnc),
              }));
            console.log(
              "Fetched Terms and Conditions:",
              this.termsAndConditions
            );
          } else {
            this.alertService.create(
              "API Error",
              "error",
              0,
              "Failed to load Terms and Conditions"
            );
          }
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            console.error("Session expired. Redirecting to login.");
            this.router.navigate(["/login"]);
          } else {
            console.error("Error fetching Terms and Conditions:", error);
          }
        }
      );
  }

  stripHtmlTags(html: string): string {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  validateMobileNumber(mobile: string): boolean {
    const mobileRegex = /^[6-9]\d{9}$/;  // Indian mobile number validation
    return mobileRegex.test(mobile);
  }
  private showError(message: string) {
    // Replace with your preferred error display method
    window.alert(message);
  }
  // mobile verification
  sendOTP() {
    const mobileNumber = this.championForm.get("mobile").value;
    const userName = this.championForm.get("firstName").value || "User";

    if (!mobileNumber) {
      this.showError("Please enter a mobile number");
      return;
    }

    if (!this.validateMobileNumber(mobileNumber)) {
      this.showError("Please enter a valid 10-digit mobile number");
      return;
    }

    this.showLoading();

    this.commonService
      .backendAPICall("/common/sendotptwillio", {
        name: userName,
        mobile: mobileNumber,
      })
      .subscribe({
        next: (response) => {
          this.generatedOTP = response["otp"];
          this.mobileOtpSent = true;
          this.mobileVerMsg = "Mobile OTP sent successfully";
          this.hideLoading();
        },
        error: (error) => {
          console.error("Error sending OTP", error);
          this.hideLoading();
          this.showError(error.error?.message || "Error sending OTP");
        }
      });
  }


  verifyOTP() {
    const enteredOTP = this.championForm.get("otp").value;
    // Show loading
    if (enteredOTP === null && enteredOTP === "") {
      window.alert("Enter OTP!");
      return;
    }
    this.showLoading();
    if (enteredOTP == this.generatedOTP) {
      this.mobileOtpSent = false;
      this.isMobileVerified = true;
      this.mobileVerMsg = "Mobile number is verified.";
      // Hide loading
      this.hideLoading();
    } else {
      // Hide loading
      this.hideLoading();
      window.alert("OTP is not correct!");
    }
  }  

  sendMailOTP() {
    const email = this.championForm.get("email").value;
    if (email === null || email === "") {
      window.alert("Email is not filled");
      return;
    }
    this.generatedMailOTP =
      Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000 + "";
    // Show loading
    this.showLoading();
    // API to send OTP
    const emailSubject = "candid verification.";
    const template = `<!DOCTYPE html>\n<html lang=\"en\">\n<head>\n  <meta charset=\"UTF-8\">\n  <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n  <title>OTP Verification</title>\n  <style>\n    body {\n      font-family: Arial, sans-serif;\n      background-color: #f4f4f4;\n      color: #333;\n    }\n    .container {\n      max-width: 600px;\n      margin: 0 auto;\n      padding: 20px;\n      background-color: #fff;\n      border-radius: 5px;\n      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    }\n    h1 {\n      color: #4285f4;\n    }\n  </style>\n</head>\n<body>\n  <div class=\"container\">\n    <h1>OTP Verification</h1>\n    <p>Hello Candid User,</p>\n    <p>Your OTP (One-Time Password) for verification is: <strong>${this.generatedMailOTP}</strong></p>\n    <p>Please use this OTP to complete the verification process.</p>\n\n    <p>If you didn't request this OTP, please ignore this email.</p>\n\n    <footer>\n      <p>Best regards,<br> Candid Verification Team</p>\n    </footer>\n  </div>\n</body>\n</html>`;

    this.commonService
      .backendAPICall("/common/send-mail", {
        sendTo: email,
        emailSubject: emailSubject,
        template: template,
      })
      .subscribe(
        (response) => {
          this.mailOtpSent = true;
          this.mailVerMsg = "Mail Otp sent Successfully";
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          console.error("Error sending OTP", error);
          // Hide loading
          this.hideLoading();
        }
      );
  }

  verifyMailOTP() {
    const enteredOTP = this.championForm.get("mailOtp").value;

    if (enteredOTP == null) {
      window.alert("Enter OTP!");
      return;
    }
    // Show loading
    this.showLoading();

    if (enteredOTP == this.generatedMailOTP) {
      this.mailOtpSent = false;
      this.isMailVerified = true;
      this.mailVerMsg = "Email is verified.";
      // Hide loading
      this.hideLoading();
    } else {
      // Hide loading
      this.hideLoading();
      window.alert("OTP is not correct!");
    }
  }
  updateChampionsList(cityID: string) {
    // Simulate fetching users based on userType
    if (cityID) {
      let filter = { cityId: cityID };
      this.commonService.getChampions().subscribe((data: any) => {
        this.champions = data.champions.map((champ) => ({
          championId: champ.championId,
          fullName: `${champ.firstName} ${champ.lastName}`,
        }));
      });
    } else {
      this.champions = [];
    }
  }

  verifyPan() {
    const panNumber = this.championForm.get("panNo").value;
    if (panNumber == null || panNumber.trim() === "") {
      window.alert("Enter PAN Number!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to verify PAN
    this.commonService
      .backendAPICall("/common/documentsVerification", {
        verFor: "pan-verification",
        bodyData: {
          requestid: "123456",
          entity: panNumber,
        },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "SUCCESS") {
            if (response.data.panStatus == "Valid") {
              this.isPanVerified = true;
              this.panVerMsg = "Pan Card is Verified";
            } else {
              window.alert("Please,Enter Valid PAN Number!");
            }
          }
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          // Hide loading
          this.hideLoading();
          window.alert("Error verifying PAN card");
        }
      );
  }
  sendAadharOtp() {
    const aadharNumber = this.championForm.get("aadharNo").value.toString();
    if (aadharNumber == null || aadharNumber.trim() === "") {
      window.alert("Enter Aadhar Number!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to verify PAN
    this.commonService
      .backendAPICall("/common/documentsVerification", {
        verFor: "aadhaar-otp",
        bodyData: {
          aadhaar: aadharNumber,
        },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "SUCCESS" || response.status == 200) {
            if (response.data) {
              this.aadharOtpSent = true;
              this.aadharVerMsg = "Aadhar Otp sent Successfully";
              this.aadharTransId = response.data.tran_id;
              this.aadharRequestid = response.requestid;
            } else {
              window.alert("Please,Enter Valid PAN Number!");
            }
          }
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          // Hide loading
          this.hideLoading();
          window.alert("Error verifying PAN card");
        }
      );
  }
  aadharVerify() {
    const aadharOtp = this.championForm.get("aadharOtp").value.toString();
    if (aadharOtp == null || aadharOtp.trim() === "") {
      window.alert("Enter Aadhar Otp!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to verify PAN
    this.commonService
      .backendAPICall("/common/documentsVerification", {
        verFor: "aadhaar-verification",
        bodyData: {
          otp: aadharOtp,
          tran_id: this.aadharTransId,
          request_id: this.aadharRequestid,
        },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "SUCCESS" || response.status == 200) {
            if (response.data) {
              this.aadharOtpSent = false;
              this.isAadharVerified = true;
              this.aadharRequestid = null;
              this.aadharTransId = null;
              this.aadharVerMsg = "Aadhar number is verified.";
              this.championForm.get("firstName").setValue(response.data.name);
              this.championForm.get("gender").setValue(response.data.gender);
              if (response.data.mobile && response.data.email) {
                this.championForm.get("mobile").setValue(response.data.mobile);
                this.championForm.get("email").setValue(response.data.email);
              }

              let addressComponents = response.data.address
                .split(",")
                .map((component) => component.trim())
                .reverse();

              let address = response.data.address
                .split(",")
                .slice(0, -3)
                .join(", ")
                .toString();

              if (addressComponents.length > 0) {
                this.championForm.get("addressLine1").setValue(address);
                // this.championForm
                //   .get("state")
                //   .setValue(addressComponents[1].toString());
                // this.championForm
                //   .get("city")
                //   .setValue(addressComponents[2].toString());
                this.championForm.get("pincode").setValue(addressComponents[0]);
              }
            } else {
              window.alert("Please,Enter Valid PAN Number!");
            }
          }
          // Hide loading
          this.hideLoading();
        },
        (error) => {
          // Hide loading
          this.hideLoading();
          window.alert("Error verifying PAN card");
        }
      );
  }
  verifyBankAccount() {
    const bankIFSCCode = this.championForm.get("bankIFSCCode").value.toString();
    const bankAccountNo = this.championForm
      .get("bankAccountNo")
      .value.toString();
    if (
      bankIFSCCode == null ||
      (bankIFSCCode.trim() === "" && bankIFSCCode == null) ||
      bankAccountNo.trim() === ""
    ) {
      window.alert("Enter Account Number and IFSC!");
      return;
    }
    // Show loading
    this.showLoading();
    // API to verify PAN
    this.commonService
      .backendAPICall("/common/documentsVerification", {
        verFor: "bank-verification",
        bodyData: {
          acc_number: bankAccountNo,
          ifsc_number: bankIFSCCode,
        },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "SUCCESS") {
            if (response.data.verification_status == "VERIFIED") {
              this.isBankVerified = true;
              this.BankVerMsg = "Bank Account is Verified";
              this.championForm
                .get("bankAccountName")
                .setValue(response.data.beneficiary_name);
              this.championForm
                .get("bankName")
                .setValue(response.data.ifscDetails.name);
              this.championForm
                .get("bankBranch")
                .setValue(response.data.ifscDetails.branch);
            } else {
              window.alert("Please,Enter Valid bank details!");
            }
          }
          // Hide loading
          this.hideLoading();
          this.isBankVerified = true;
          this.BankVerMsg = "Bank Account is Verified";
        },
        (error) => {
          console.log("error");
          console.log(error);

          // Hide loading
          this.hideLoading();
          window.alert("Error verifying bank details");
        }
      );
    // Hide loading
    this.hideLoading();
    this.isBankVerified = true;
    this.BankVerMsg = "Bank Account is Verified";
  }
  selectPanPhoto(event: any) {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "application/pdf"
      ) {
        this.readFile(file, "pan");
      } else {
        // Handle invalid file type error
        alert("Please select a JPG/PNG/PDF file for PAN photo.");
        event.target.value = ""; // Clear the file input
      }
    }
  }

  // Method to handle selection of Aadhar photo
  selectAadharPhoto(event: any) {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "application/pdf"
      ) {
        this.readFile(file, "aadhar");
      } else {
        // Handle invalid file type error
        alert("Please select a JPG/PNG/PDF file for Aadhar photo.");
        event.target.value = ""; // Clear the file input
      }
    }
  }
  selectBankChequePhoto(event: any) {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "application/pdf"
      ) {
        this.readFile(file, "bankcheque");
      } else {
        alert("Please select a JPG/PNG/PDF file for Bank Cheque photo.");
        event.target.value = "";
      }
    }
  }
  selectProfilePhoto(event: any) {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/jpeg" ||
        fileType === "image/png" ||
        fileType === "application/pdf"
      ) {
        this.readFile(file, "profilePhoto");
      } else {
        alert("Please select a JPG/PNG/PDF file for Bank Cheque photo.");
        event.target.value = "";
      }
    }
  }

  // Method to read file and set photo
  readFile(file: File, type: string) {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      if (type === "pan") {
        this.panPhoto = e.target.result;
        this.championForm.controls["panPhotoData"].setValue(e.target.result);
      } else if (type === "bankcheque") {
        this.bankChequePhoto = e.target.result;
        this.championForm.controls["bankCancelChqPhotoData"].setValue(
          e.target.result
        );
      } else if (type === "profilePhoto") {
        this.profilePhoto = e.target.result;
        this.championForm.controls["profilePhotoData"].setValue(
          e.target.result
        );
      } else {
        this.championForm.controls["aadharPhotoData"].setValue(e.target.result);
        this.aadharPhoto = e.target.result;
      }
    };
    reader.readAsDataURL(file);
  }

  onCitySelect(city: City) {
    this.championForm.get("cityId").setValue(city.cityId);
    this.championForm.get("cityName").setValue(city.cityName);
  }

  onCityDeSelect(city: City) {
    this.championForm.get("cityId").setValue(null);
    this.championForm.get("cityName").setValue(null);
  }

  formatDateForDB(date: Date): string {
    return (
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0") +
      " " +
      String(date.getHours()).padStart(2, "0") +
      ":" +
      String(date.getMinutes()).padStart(2, "0") +
      ":" +
      String(date.getSeconds()).padStart(2, "0")
    );
  }

  calculateEndDate(
    startDate: Date,
    validityPeriod: number,
    validityPeriodType: string
  ): Date {
    const endDate = new Date(startDate);

    switch (validityPeriodType) {
      case "D":
        endDate.setDate(endDate.getDate() + validityPeriod);
        break;
      case "M":
        endDate.setMonth(endDate.getMonth() + validityPeriod);
        break;
      case "Y":
        endDate.setFullYear(endDate.getFullYear() + validityPeriod);
        break;
      default:
        console.error("Invalid validity period type");
        return null;
    }

    return endDate;
  }

  onSubmit() {
    const today = new Date();
    // Format and set start date
    const formattedStartDate = this.formatDateForDB(today);
    this.championForm.get("startDate").setValue(formattedStartDate);

    // Find selected package
    const selectedPackage = this.championSubscriptionPackages[1];
    console.log(this.championSubscriptionPackages);
    
    if (selectedPackage) {
      // Calculate end date based on validity period and type
      const endDate = this.calculateEndDate(
        today,
        Number(selectedPackage.validityPeriod),
        selectedPackage.validityPeriodType
      );

      if (endDate && !isNaN(endDate.getTime())) {
        const formattedEndDate = this.formatDateForDB(endDate);

        this.championForm.get("endDate").setValue(formattedEndDate);
      } else {
        console.error("Invalid end date calculation");
        return;
      }
    }
    this.championForm
      .get("championSubscriptionPackageId")
      .setValue(
        this.championSubscriptionPackages[1].championSubscriptionPackageId
      );
    if (this.championForm.valid) {
      this.loading = true;
      const apiURL = "/co-master/addChampion";

      // Create form data with properly formatted dates
      const formData = {
        ...this.championForm.value,
        startDate: this.championForm.get("startDate").value,
        endDate: this.championForm.get("endDate").value,
        paymentMethod: this.paymentMethod,
        paymentStatus: this.paymentMethod === 'cheque' ? '1' : this.championForm.get('paymentStatus').value,
        chequeImage: this.chequeImage // Make sure this is included
      };

      if (this.paymentMethod === 'cheque' && this.chequeImage) {
        formData['chequeImage'] = this.chequeImage;
      }

      this.commonService
        .addChampion(this.tempUserToken, apiURL, formData)
        .subscribe({
          next: (result) => {
            if (result["responseStatus"] === "Ok") {
              window.alert(
                this.paymentMethod === 'cheque' 
                  ? "Champion registration with cheque payment request sent successfully"
                  : "Champion registration request sent successfully"
              );
              this.router.navigate(["/"]);
            } else {
              window.alert(result["message"]);
            }
            this.loading = false;
          },
          error: (error) => {
            window.alert(error["message"]);
            console.error("API Error:", error);
            this.loading = false;
          },
        });
    }
  }

  onChampionPackageTypeSelect(
    championSubscriptionPackages: ChampionPackageType
  ) {
    this.championForm
      .get("championSubscriptionPackageId")
      .setValue(championSubscriptionPackages.championSubscriptionPackageId);
    this.championForm
      .get("packageTitle")
      .setValue(championSubscriptionPackages.packageTitle);
    console.log(championSubscriptionPackages);
  }

  onChampionPackageTypeDeSelect(
    championSubscriptionPackages: ChampionPackageType
  ) {
    this.championForm.get("championSubscriptionPackageId").setValue(null);
    this.championForm.get("packageTitle").setValue(null);
  }

  onPaymentMethodChange(method: 'online' | 'cheque') {
    // Prevent execution if same method is selected
    if (this.paymentMethod === method) return;
    
    this.paymentMethod = method;
    this.showChequeUpload = method === 'cheque';
    // Reset cheque image when switching payment methods
    if (method === 'online') {
      this.championForm.patchValue({ chequeImage: null });
    }
  }

  onChequeImageUpload(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      
      if (!file.type.startsWith('image/')) {
        alert('Please upload an image file');
        return;
      }
      
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.chequeImage = e.target.result; // Store the base64 string
        this.championForm.patchValue({
          chequeImage: e.target.result
        });
      };
      reader.readAsDataURL(file);
    }
  }
  
  async submitPayment() {
    this.loading = true;
    try {
      const response: any = await this.commonService
        .backendAPICall("/payment/initiate", {
          amount: this.paymentData.amount,
        })
        .toPromise();

      const RozarpayOptions = {
        description: "candidoffers payment",
        currency: "INR",
        amount: response.order.amount,
        order_id: response.order.id,
        name: "Candidoffers",
        key: "rzp_live_mXMqD6Uq31IPNc",
        image:
          "https://candidoffers.com/assets/images/candid-offer-footer-logo.png",
        prefill: {
          name: this.championForm.get("firstName").value,
          email: this.championForm.get("email").value,
          phone: this.championForm.get("mobile").value,
        },
        theme: {
          color: "#B9350D",
        },
        handler: async (response) => {
          this.ngZone.run(async () => {
            if (response.razorpay_payment_id) {
              try {
                const verifyResponse: any = await this.commonService
                  .backendAPICall("/payment/verify", {
                    payment_id: response.razorpay_payment_id,
                  })
                  .toPromise();

                // Update form values and UI state
                this.updatePaymentStatus(verifyResponse);
              } catch (error) {
                console.error("Payment verification failed:", error);
                this.handlePaymentError();
              }
            } else {
              console.log("Payment ID not received:", response);
              this.handlePaymentError();
            }
          });
        },
        modal: {
          ondismiss: () => {
            this.ngZone.run(() => {
              console.log("Payment modal dismissed");
              this.loading = false;
              this.cdr.detectChanges();
            });
          },
        },
      };

      const razorpay = new Razorpay(RozarpayOptions);
      razorpay.open();
    } catch (error) {
      console.error("Payment initiation failed:", error);
      this.handlePaymentError();
    }
  }

  private updatePaymentStatus(verifyResponse: any) {
    const today = new Date();
    // Format and set start date
    const formattedStartDate = this.formatDateForDB(today);
    
    // Find selected package
    const selectedPackage = this.championSubscriptionPackages[1];
    let formattedEndDate = '';
  
    if (selectedPackage) {
      // Calculate end date based on validity period and type
      const endDate = this.calculateEndDate(
        today,
        Number(selectedPackage.validityPeriod),
        selectedPackage.validityPeriodType
      );
  
      if (endDate && !isNaN(endDate.getTime())) {
        formattedEndDate = this.formatDateForDB(endDate);
      } else {
        console.error("Invalid end date calculation");
        this.handlePaymentError();
        return;
      }
    }
  
    // Update form values
    this.championForm.patchValue({
      paymentStatus: "2",
      paymentAmount: this.paymentData.amount,
      paymentMethod: verifyResponse.payment.method,
      paymentId: verifyResponse.payment.id,
      orderId: verifyResponse.payment.order_id,
      startDate: formattedStartDate,
      endDate: formattedEndDate
    });
  
    // Update UI state
    this.showPayment = false;
    if (this.championForm.valid) {
    // Call addChampion API
    const apiURL = "/co-master/addChampion";
    const formData = {
      ...this.championForm.value,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      paymentMethod: 'online',
      paymentStatus: '2'
    };
  
    this.commonService
      .addChampion(this.tempUserToken, apiURL, formData)
      .subscribe({
        next: (result) => {
          if (result["responseStatus"] === "Ok") {
            window.alert("Champion registration request sent successfully");
            this.router.navigate(["/"]);
          } else {
            window.alert(result["message"]);
          }
          this.loading = false;
        },
        error: (error) => {
          window.alert(error["message"]);
          console.error("API Error:", error);
          this.loading = false;
        },
      });
  
    // Force change detection
    this.cdr.detectChanges();
  
    // Scroll to top smoothly
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }}

  private handlePaymentError() {
    this.ngZone.run(() => {
      this.loading = false;
      this.showPayment = true;
      this.cdr.detectChanges();
    });
  }
}
