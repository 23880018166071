import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Renderer2, ElementRef } from "@angular/core";

@Component({
  selector: "account-deletion",
  templateUrl: "./account-deletion.component.html",
})
export class AccountDeletionComponent {
  loading: boolean = false;
  mobileOtp: string = "";
  generatedMailOTP: string = "";
  mobileVerified: boolean = false;
  mailVerified: boolean = false;
  deleteForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.deleteForm = this.fb.group({
      username: ["", Validators.required],
      mobile: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
      email: ["", [Validators.required, Validators.email]],
      reason: ["", Validators.required],
      mobileOTP: ["", Validators.maxLength(4)],
      emailOTP: ["", Validators.maxLength(4)],
    });
  }

  get cf() {
    return this.deleteForm.controls;
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  sendMobileOTP() {
    this.showLoading();
    const mobileNumber = this.deleteForm.get("mobile")?.value;
    this.http
      .post<any>("https://candidoffers.com:3636/api/common/sendOtp", {
        mobile: mobileNumber,
      })
      .subscribe(
        (response) => {
          this.hideLoading();
          if (response.status) {
            this.toggleClass("mobileOTPVerification", "hidden", false);
            this.disableSendOTPButton("sendMobileOTP", 30);
            this.mobileOtp = response.otp;
            this.deleteForm
              .get("mobileOTP")
              ?.setValidators([Validators.required, Validators.maxLength(4)]);
          } else {
            alert("Failed to send OTP to mobile.");
          }
        },
        (error) => {
          console.error("Error sending OTP to mobile:", error);
          alert("Failed to send OTP to mobile.");
          this.hideLoading();
        }
      );
  }

  verifyMobileOTP() {
    this.showLoading();
    const mobileOTP = this.deleteForm.get("mobileOTP")?.value;
    if (mobileOTP === this.mobileOtp) {
      this.mobileVerified = true;
      this.toggleClass("mobileOTPVerification", "hidden", true);
      this.toggleClass("isMobileVerified", "hidden", false);
      this.toggleClass("sendMobileOTP", "hidden", true);
      this.hideLoading();
    } else {
      alert("Incorrect OTP. Please try again.");
      this.hideLoading();
    }
  }

  sendEmailOTP() {
    this.showLoading();
    const email = this.deleteForm.get("email")?.value;
    const emailSubject = "candid verification.";
    this.generatedMailOTP =
      Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000 + "";
    const template = `<!DOCTYPE html>\n<html lang=\"en\">\n<head>\n  <meta charset=\"UTF-8\">\n  <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n  <title>OTP Verification</title>\n  <style>\n    body {\n      font-family: Arial, sans-serif;\n      background-color: #f4f4f4;\n      color: #333;\n    }\n    .container {\n      max-width: 600px;\n      margin: 0 auto;\n      padding: 20px;\n      background-color: #fff;\n      border-radius: 5px;\n      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    }\n    h1 {\n      color: #4285f4;\n    }\n  </style>\n</head>\n<body>\n  <div class=\"container\">\n    <h1>OTP Verification</h1>\n    <p>Hello Candid User,</p>\n    <p>Your OTP (One-Time Password) for verification is: <strong>${this.generatedMailOTP}</strong></p>\n    <p>Please use this OTP to complete the verification process.</p>\n\n    <p>If you didn't request this OTP, please ignore this email.</p>\n\n    <footer>\n      <p>Best regards,<br> Candid Verification Team</p>\n    </footer>\n  </div>\n</body>\n</html>`;

    this.http.post<any>("https://candidoffers.com:3636/api/common/send-mail", {
        sendTo: email,
        emailSubject: emailSubject,
        template: template,
      })
      .subscribe(
        (response) => {
          this.hideLoading();
          if (response.status) {
            this.toggleClass("emailOTPVerification", "hidden", false);
            this.disableSendOTPButton("sendEmailOTP", 30);
            this.deleteForm
              .get("emailOTP")
              ?.setValidators([Validators.required, Validators.maxLength(4)]);
          } else {
            alert("Failed to send OTP to email.");
          }
        },
        (error) => {
          console.error("Error sending OTP to email:", error);
          alert("Failed to send OTP to email.");
          this.hideLoading();
        }
      );
  }

  verifyEmailOTP() {
    this.showLoading();
    const emailOTP = this.deleteForm.get("emailOTP")?.value;
    if (emailOTP === this.generatedMailOTP) {
      this.mailVerified = true;
      this.toggleClass("emailOTPVerification", "hidden", true);
      this.toggleClass("isMailVerified", "hidden", false);
      this.toggleClass("sendEmailOTP", "hidden", true);
      this.hideLoading();
    } else {
      alert("Incorrect OTP. Please try again.");
      this.hideLoading();
    }
  }

  disableSendOTPButton(buttonId: string, durationSeconds: number) {
    const button = this.el.nativeElement.querySelector(`#${buttonId}`);
    
    if (button) {
      this.renderer.setProperty(button, "disabled", true);
      this.renderer.setProperty(
        button,
        "innerText",
        `Resend OTP (${durationSeconds}s)`
      );
      setTimeout(() => {
        this.renderer.setProperty(button, "disabled", false);
        this.renderer.setProperty(button, "innerText", "Send OTP");
      }, durationSeconds * 1000);
    }
  }

  toggleClass(elementId: string, className: string, addClass: boolean) {
    const element = this.el.nativeElement.querySelector(`#${elementId}`);
    if (element) {
      if (addClass) {
        this.renderer.addClass(element, className);
      } else {
        this.renderer.removeClass(element, className);
      }
    }
  }

  submitDeleteRequest() {
    if (this.mailVerified && this.mobileVerified) {
      this.showLoading();
      const formData = this.deleteForm.value;
      this.http
        .post<any>("https://candidoffers.com:3636/api/common/soft-delete", {
          mobile: "+91" + formData.mobile.toString(),         
        })
        .subscribe(
          (response) => {
            this.hideLoading();
            if (response.status) {
              alert("Account deletion request submitted successfully.");
            } else {
              alert("Failed to submit account deletion request.");
            }
          },
          (error) => {
            console.error("Error submitting account deletion request:", error);
            alert("Failed to submit account deletion request.");
            this.hideLoading();
          }
        );
    } else {
      alert("Please verify your mobile and email before submitting.");
    }
  }
}
