<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Dashboard</title>
    <link href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0/css/bootstrap.min.css" rel="stylesheet">
    <style>
        :root {
            --primary: #4f46e5;
            --secondary: #6366f1;
            --success: #22c55e;
            --danger: #ef4444;
            --warning: #f59e0b;
            --info: #3b82f6;
            --light: #f8fafc;
            --dark: #1e293b;
            --shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
            --shadow-hover: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
            --radius: 0.75rem;
        }

        body {
            background-color: var(--light);
            font-family: system-ui, -apple-system, sans-serif;
        }

        .vendor-offers-table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
        }

        .vendor-offers-table th,
        .vendor-offers-table td {
            padding: 12px 15px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }

        .vendor-offers-table th {
            background-color: #f4f4f4;
            font-weight: bold;
        }

        .plan-details {
            width: 100%;
        }

        .plan-row {
            display: flex;
            align-items: center;
            border-bottom: 1px dashed #dee2e6;
            padding: 8px 0;
            margin-bottom: 4px;
        }

        .plan-row:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }

        .plan-name {
            flex: 1;
            padding-right: 15px;
        }

        .plan-cost {
            min-width: 100px;
            text-align: right;
            padding-left: 15px;
            border-left: 1px solid #dee2e6;
        }

        .plan-end-date {
            min-width: 100px;
            text-align: right;
            padding-left: 15px;
            border-left: 1px solid #dee2e6;
        }

        .seller-info p {
            margin-bottom: 0.5rem;
        }

        .seller-info p:last-child {
            margin-bottom: 0;
        }

        .vendor-offers-table tr:nth-child(even) {
            background-color: #f9f9f9;
        }

        .vendor-offers-table tr:hover {
            background-color: #f1f1f1;
        }

        .card {
            background: white;
            border: none;
            border-radius: var(--radius);
            box-shadow: var(--shadow);
            transition: all 0.3s ease;
            margin-bottom: 1.5rem;
        }

        .card:hover {
            box-shadow: var(--shadow-hover);
        }
        .plan-row {
    border-bottom: 1px solid #eee;
    padding: 8px 0;
}

.plan-row:last-child {
    border-bottom: none;
}

.plan-info {
    flex: 1;
}

.plan-validity {
    min-width: 120px;
    text-align: right;
}

.seller-info p {
    margin-bottom: 0.5rem;
}

.card-header .total-summary {
    font-size: 1rem;
}
        .card-header {
            background: white;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding: 1.25rem;
        }

        .section-header {
            color: var(--primary);
            font-size: 1.25rem;
            font-weight: 600;
            margin: 0;
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        .section-header i {
            font-size: 1.5rem;
        }

        .btn-export {
            padding: 0.75rem 1.5rem;
            border-radius: var(--radius);
            font-weight: 600;
            color: white;
            border: none;
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;
            transition: all 0.3s ease;
        }

        .btn-export:hover {
            transform: translateY(-2px);
            box-shadow: var(--shadow);
        }

        .btn-export-kit {
            background: linear-gradient(135deg, var(--success), #16a34a);
        }

        .btn-export-champion {
            background: linear-gradient(135deg, var(--info), #2563eb);
        }

        .btn-export-targets {
            background: linear-gradient(135deg, var(--primary), var(--secondary));
        }

        .btn-export-offer {
            background: linear-gradient(135deg, var(--warning), #d97706);
        }

        .table {
            margin: 0;
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
        }

        .table th {
            background: var(--light);
            color: var(--primary);
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.875rem;
            padding: 1rem;
            border-bottom: 2px solid #e2e8f0;
        }

        .table td {
            padding: 1rem;
            vertical-align: middle;
            border-bottom: 1px solid #e2e8f0;
        }

        .table tr:hover {
            background: var(--light);
        }

        .badge {
            padding: 0.5em 1em;
            border-radius: 9999px;
            font-weight: 600;
            font-size: 0.75rem;
            display: inline-flex;
            align-items: center;
            gap: 0.25rem;
        }

        .bg-success {
            background: linear-gradient(135deg, var(--success), #16a34a) !important;
        }

        .bg-danger {
            background: linear-gradient(135deg, var(--danger), #dc2626) !important;
        }

        .text-amount {
            font-family: monospace;
            font-weight: 600;
        }

        .text-success {
            color: var(--success) !important;
        }

        .text-danger {
            color: var(--danger) !important;
        }

        .form-select {
            border-radius: var(--radius);
            padding: 0.75rem 1rem;
            border: 1px solid #e2e8f0;
            font-size: 1rem;
            transition: all 0.3s ease;
        }
        .card {
    border: none;
    margin-bottom: 2rem;
}

.card-header {
    border-bottom: 1px solid rgba(0,0,0,.125);
    background-color: #FFB800 !important;
    color: black;
}

.table {
    margin-bottom: 0;
}

.table th {
    background-color: #FFF9E6;
    font-weight: 600;
}

.table td, .table th {
    padding: .75rem;
    vertical-align: middle;
}

.badge {
    font-size: 0.875rem;
    padding: 0.5em 1em;
}

.seller-info p {
    margin-bottom: 0.5rem;
}

.plan-row:not(:last-child) {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 0.5rem;
}

.table-responsive {
    border-radius: 0.25rem;
}
        .form-select:focus {
            border-color: var(--primary);
            box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
        }

        .empty-state {
            text-align: center;
            padding: 2rem;
            color: #64748b;
        }

        .empty-state i {
            font-size: 2.5rem;
            margin-bottom: 1rem;
            color: #94a3b8;
        }

        @media (max-width: 768px) {
            .card-header {
                flex-direction: column;
                gap: 1rem;
            }

            .btn-export {
                width: 100%;
            }

            .table th,
            .table td {
                padding: 0.75rem;
                font-size: 0.875rem;
            }
        }

        :root {
            --primary-color: #1a237e;
            --secondary-color: #283593;
            --accent-color: #3949ab;
            --success-color: #43a047;
            --danger-color: #e53935;
            --light-bg: #f5f7fa;
            --border-radius: 12px;
            --card-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            --hover-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        }

        body {
            background-color: var(--light-bg);
            font-family: 'Segoe UI', system-ui, -apple-system, sans-serif;
            color: #2d3748;
        }

        .dashboard {
            padding: 2rem 0;
        }

        .dashboard-block {
            background: white;
            border-radius: var(--border-radius);
            box-shadow: var(--card-shadow);
            padding: 2rem;
            margin-bottom: 2rem;
            transition: all 0.3s ease;
        }

        .dashboard-block:hover {
            box-shadow: var(--hover-shadow);
        }

        .chart-container {
            background: white;
            border-radius: var(--border-radius);
            padding: 1.5rem;
            margin-bottom: 1.5rem;
            box-shadow: var(--card-shadow);
            position: relative;
            min-height: 400px;
            transition: all 0.3s ease;
        }

        .chart-container:hover {
            box-shadow: var(--hover-shadow);
        }

        .demo-chart {
            height: 400px;
            margin: 1rem 0;
        }

        .card {
            border: none;
            box-shadow: var(--card-shadow);
            border-radius: var(--border-radius);
            margin-bottom: 2rem;
            transition: all 0.3s ease;
            overflow: hidden;
        }

        .card:hover {
            box-shadow: var(--hover-shadow);
        }

        .card-header {
            background-color: white;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding: 1.5rem;
        }

        .card-body {
            padding: 1.5rem;
        }

        .table {
            margin-bottom: 0;
            border-collapse: separate;
            border-spacing: 0;
        }

        .table th {
            background-color: var(--light-bg);
            color: var(--primary-color);
            font-weight: 600;
            padding: 1rem;
            border-bottom: 2px solid #e2e8f0;
            text-transform: uppercase;
            font-size: 0.875rem;
            letter-spacing: 0.05em;
        }

        .table td {
            padding: 1rem;
            vertical-align: middle;
            border-bottom: 1px solid #e2e8f0;
            color: #4a5568;
        }

        .table tbody tr:hover {
            background-color: #f8fafc;
        }

        .btn-export {
            padding: 0.75rem 1.5rem;
            border-radius: var(--border-radius);
            font-weight: 600;
            min-width: 160px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            transition: all 0.3s ease;
            text-transform: uppercase;
            letter-spacing: 0.025em;
            font-size: 0.875rem;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .btn-export:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
        }

        .btn-export-kit {
            background: linear-gradient(135deg, #43a047 0%, #2e7d32 100%);
            color: white;
        }

        .btn-export-champion {
            background: linear-gradient(135deg, #1e88e5 0%, #1565c0 100%);
            color: white;
        }

        .btn-export-targets {
            background: linear-gradient(135deg, #8e24aa 0%, #6a1b9a 100%);
            color: white;
        }

        .btn-export-offer {
            background: linear-gradient(135deg, #fb8c00 0%, #ef6c00 100%);
            color: white;
        }

        .table {
            width: 100%;
            margin: 0;
            border-collapse: collapse;
        }

        .table th,
        .table td {
            text-align: left;
            padding: 8px;
            border: 1px solid #ddd;
        }

        .table th {
            background-color: #f4f4f4;
            font-weight: bold;
        }

        .table-striped tbody tr:nth-of-type(odd) {
            background-color: #f9f9f9;
        }

        .table-bordered {
            border: 1px solid #ddd;
        }

        .form-select {
            border-radius: var(--border-radius);
            padding: 0.75rem 1rem;
            border: 1px solid #e2e8f0;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
            font-size: 1rem;
            color: #4a5568;
            transition: all 0.3s ease;
        }

        .form-select:focus {
            border-color: var(--accent-color);
            box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.25);
        }

        .badge {
            padding: 0.5em 1em;
            border-radius: 9999px;
            font-weight: 600;
            font-size: 0.75rem;
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }

        .bg-success {
            background: linear-gradient(135deg, #43a047 0%, #2e7d32 100%) !important;
        }

        .bg-danger {
            background: linear-gradient(135deg, #e53935 0%, #c62828 100%) !important;
        }

        .qr-code-container {
            background: white;
            padding: 2rem;
            border-radius: var(--border-radius);
            box-shadow: var(--card-shadow);
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 300px;
            transition: all 0.3s ease;
        }

        .qr-code-container:hover {
            box-shadow: var(--hover-shadow);
        }

        .section-header {
            color: var(--primary-color);
            font-size: 1.25rem;
            font-weight: 700;
            margin-bottom: 1.5rem;
            letter-spacing: 0.025em;
            position: relative;
            padding-bottom: 0.5rem;
        }

        .section-header::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 40px;
            height: 3px;
            background: linear-gradient(to right, var(--primary-color), var(--accent-color));
            border-radius: 3px;
        }

        .table-responsive {
            border-radius: var(--border-radius);
            overflow: hidden;
        }

        @media (max-width: 768px) {
            .dashboard-block {
                padding: 1rem;
            }

            .btn-export {
                width: 100%;
                margin-top: 1rem;
            }

            .card-header {
                flex-direction: column;
                gap: 1rem;
            }

            .section-header {
                font-size: 1.125rem;
            }

            .table th,
            .table td {
                padding: 0.75rem;
                font-size: 0.875rem;
            }
        }

        /* Empty State Styling */
        .empty-state {
            text-align: center;
            padding: 2rem;
            color: #718096;
        }

        .empty-state i {
            font-size: 2.5rem;
            margin-bottom: 1rem;
            color: #a0aec0;
        }

        /* Smooth Scrolling */
        html {
            scroll-behavior: smooth;
        }

        /* Table Loading Shimmer Effect */
        @keyframes shimmer {
            0% {
                background-position: -1000px 0;
            }

            100% {
                background-position: 1000px 0;
            }
        }

        .loading {
            background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
            background-size: 1000px 100%;
            animation: shimmer 2s infinite linear;
        }

        .commission-control {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .commission-control input {
            width: 80px;
            text-align: right;
        }

        .commission-control button {
            white-space: nowrap;
        }

        .analytics-section {
            margin: 2rem 0;
        }

        .analytics-card {
            background: white;
            border-radius: 16px;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
            overflow: hidden;
        }

        .analytics-header {
            background: linear-gradient(135deg, #4f46e5, #6366f1);
            padding: 2rem;
            color: white;
        }

        .header-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .title-section h3 {
            margin: 0;
            font-size: 1.5rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        .subtitle {
            margin: 0.5rem 0 0;
            opacity: 0.9;
            font-size: 0.9rem;
        }

        .commission-control {
            background: rgba(255, 255, 255, 0.1);
            padding: 1rem;
            border-radius: 12px;
        }

        .rate-control label {
            display: block;
            margin-bottom: 0.5rem;
            font-size: 0.9rem;
            opacity: 0.9;
        }

        .custom-input {
            background: rgba(255, 255, 255, 0.9);
            border: none;
            width: 80px;
            text-align: center;
        }

        .btn-update {
            background: #22c55e;
            color: white;
            border: none;
            padding: 0.5rem 1rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            transition: all 0.3s ease;
        }

        .btn-update:hover {
            background: #16a34a;
        }

        .analytics-body {
            padding: 2rem;
        }

        .stats-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
            gap: 1.5rem;
            margin-bottom: 2rem;
        }

        .stat-card {
            display: flex;
            align-items: center;
            padding: 1.5rem;
            border-radius: 12px;
            color: white;
            transition: transform 0.3s ease;
        }

        .stat-card:hover {
            transform: translateY(-5px);
        }

        .stat-card.primary { background: linear-gradient(135deg, #4f46e5, #6366f1); }
        .stat-card.success { background: linear-gradient(135deg, #22c55e, #16a34a); }
        .stat-card.info { background: linear-gradient(135deg, #3b82f6, #2563eb); }
        .stat-card.warning { background: linear-gradient(135deg, #f59e0b, #d97706); }

        .stat-icon {
            font-size: 2rem;
            margin-right: 1rem;
        }

        .stat-details h4 {
            margin: 0;
            font-size: 0.9rem;
            opacity: 0.9;
        }

        .stat-value {
            margin: 0;
            font-size: 1.5rem;
            font-weight: 600;
        }

        .revenue-details {
            background: #f8fafc;
            border-radius: 12px;
            padding: 1.5rem;
        }

        .details-title {
            margin: 0 0 1rem;
            color: #1e293b;
            font-size: 1.1rem;
        }

        .details-table {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .table-row {
            display: flex;
            justify-content: space-between;
            padding: 0.75rem;
            background: white;
            border-radius: 8px;
            transition: all 0.3s ease;
        }

        .table-row:hover {
            background: #f1f5f9;
        }

        .table-row.total {
            background: #1e293b;
            color: white;
            margin-top: 0.5rem;
        }

        .row-label {
            font-weight: 500;
        }

        .row-value {
            font-weight: 600;
        }

        .row-value.highlight {
            color: #22c55e;
        }

        @media (max-width: 768px) {
            .header-content {
                flex-direction: column;
                gap: 1rem;
                text-align: center;
            }
            
            .commission-control {
                width: 100%;
            }
            
            .stats-grid {
                grid-template-columns: 1fr;
            }
        }

        .commission-settings {
            background: rgba(255, 255, 255, 0.1);
            padding: 1.5rem;
            border-radius: 12px;
            width: 100%;
        }

        .settings-title {
            color: white;
            font-size: 1.1rem;
            margin-bottom: 1rem;
            font-weight: 500;
        }

        .rates-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            gap: 1rem;
            align-items: end;
        }

        .rate-control {
            background: rgba(255, 255, 255, 0.05);
            padding: 1rem;
            border-radius: 8px;
        }

        .rate-control label {
            display: block;
            margin-bottom: 0.5rem;
            font-size: 0.9rem;
            color: rgba(255, 255, 255, 0.9);
        }

        .custom-input {
            background: rgba(255, 255, 255, 0.9);
            border: none;
            border-radius: 4px;
            padding: 0.5rem;
            color: #1a1a1a;
            width: 100%;
        }

        .input-group {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        .input-group-text {
            background: rgba(255, 255, 255, 0.2);
            color: white;
            border: none;
            padding: 0.5rem;
            border-radius: 4px;
        }

        .btn-update {
            background: #22c55e;
            color: white;
            border: none;
            padding: 0.75rem 1.5rem;
            border-radius: 8px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            transition: all 0.3s ease;
            width: 100%;
            margin-top: 1rem;
        }

        .btn-update:hover {
            background: #16a34a;
            transform: translateY(-2px);
        }

        @media (max-width: 768px) {
            .rates-container {
                grid-template-columns: 1fr;
            }
            
            .commission-settings {
                padding: 1rem;
            }
        }

        /* Add a nice hover effect for the rate control boxes */
        .rate-control:hover {
            background: rgba(255, 255, 255, 0.1);
            transform: translateY(-2px);
            transition: all 0.3s ease;
        }

        /* Add a subtle glow effect for the active input */
        .custom-input:focus {
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
            outline: none;
        }

        .table-warning {
            background-color: #fff3cd !important;
        }
        
        .table-light {
            background-color: #f8f9fa !important;
        }
        
        .table-bordered > :not(caption) > * > * {
            border-width: 1px;
            padding: 12px;
        }
        
        .table > thead {
            background-color: #f8f9fa;
            font-weight: 500;
        }
    </style>
</head>

<body>
    <div class="container-fluid">
        <div class="dashboard">
            <div class="dashboard-block">
                <!-- City Filter -->
                <!-- <div class="row mb-4">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cityFilter" class="section-header">
                            <i class="bi bi-geo-alt"></i>
                            Filter by City
                        </label>
                        <select id="cityFilter" class="form-select" [(ngModel)]="selectedCity" (change)="onCityChange($event)">
                            <option value="all">All Cities</option>
                            <option *ngFor="let city of cities" [value]="city.cityName">
                                {{city.cityName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div> -->

                <div class="row" *ngIf="isDrillDownOffersChart">
                    <!-- Encashment Status by Vendor Table (Left Column) -->
                    <div class="rewards-section d-flex justify-content-center align-items-center" style="height: 10vh;">
                        <div class="text-center" style="border-top: 2px solid black; border-bottom: 2px solid black; width: 100%; padding: 10px;">
                            <span style="font-weight: bold;">TARGETS</span>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex justify-content-between align-items-center">
                                <h3 class="section-header">
                                    <i class="bi bi-bullseye"></i>
                                    User Targets
                                </h3>
                                <button (click)="exportAsXLSX()" class="btn-export btn-export-offer">
                                    <i class="bi bi-download"></i>
                                    Export Targets
                                </button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th><i class="bi bi-hash"></i> Net Target</th>
                                            <th><i class="bi bi-hash"></i> Target Achieved</th>
                                            <th><i class="bi bi-hash"></i> Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="excel4 && excel4.length > 0; else noTargets">
                                        <tr *ngFor="let item of excel4">
                                            <td>{{item.targetNumber}}</td>
                                            <td>{{item.targetAchieved}}</td>
                                            <td>{{item.balance}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ng-template #noTargets>
                                    <tr>
                                        <td colspan="4" class="text-center">
                                            <div class="py-5">
                                                <i class="bi bi-bullseye text-muted" style="font-size: 4rem;"></i>
                                                <h4 class="mt-3 text-muted">No Targets Available</h4>
                                                <p class="text-muted mb-4">There are no user targets to display at this time.</p>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="rewards-section d-flex justify-content-center align-items-center" style="height: 10vh;">
                        <div class="text-center" style="border-top: 2px solid black; border-bottom: 2px solid black; width: 100%; padding: 10px;">
                            <span style="font-weight: bold;">DATA FOR REWARDS</span>
                        </div>
                    </div>
                    
                    
                    <div class="card mb-4" *ngIf="userGroupName !== 'CHAMPION'">
                        <div class="card-header">
                            <div class="d-flex justify-content-between align-items-center">
                                <h3 class="section-header">
                                    <i class="bi bi-star"></i>
                                    REFERRED SUPER CHAMPIONS
                                </h3>
                                <!-- <button (click)="exportAsXLSXSuperChampion()" class="btn-export btn-export-targets">
                                    <i class="bi bi-download"></i>
                                    Export Super Champions
                                </button> -->
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered table-hover">
                                    <thead class="table-light">
                                        <tr>
                                            <th>Super Champion Name</th>
                                            <th>Super Champion ID</th>
                                            <th>City</th>
                                            <th>Status (Active/De-Active)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="superChampions && superChampions.length > 0; else noSuperChampions">
                                            <tr *ngFor="let champion of superChampions">
                                                <td>{{ champion.championName || 'Not Provided' }}</td>
                                                <td>
                                                    <input type="text" [value]="champion.championCode || 'Not Provided'"
                                                        readonly class="form-control" style="cursor: pointer;"
                                                        (click)="selectText($event)">
                                                </td>
                                                <td>{{ champion.cityName || 'Not Provided' }}</td>
                                                <td>{{ champion.status === '1' ? 'Active' : 'Inactive' }}</td>
                                            </tr>
                                        </ng-container>
                                        <ng-template #noSuperChampions>
                                            <tr>
                                                <td colspan="4" class="text-center">
                                                    <div class="py-5">
                                                        <i class="bi bi-star text-muted" style="font-size: 4rem;"></i>
                                                        <h4 class="mt-3 text-muted">No Referred Super Champions</h4>
                                                        <p class="text-muted mb-4">Please refer and earn rewards by onboarding new super champions.</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div class="card mb-4">
                        <div class="card-header">
                            <div class="d-flex justify-content-between align-items-center">
                                <h3 class="section-header">
                                    <i class="bi bi-trophy"></i>
                                    <ng-container *ngIf="userGroupName === 'CHAMPION'">
                                        REFERRED CHAMPIONS
                                    </ng-container>
                                    <ng-container *ngIf="userGroupName !== 'CHAMPION'">
                                        CHAMPIONS ONBOARDED
                                    </ng-container>
                                </h3>
                                <button (click)="exportAsXLSXChampion()" class="btn-export btn-export-targets">
                                    <i class="bi bi-download"></i>
                                    Export Champions
                                </button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered table-hover">
                                    <thead class="table-light">
                                        <tr>
                                            <th>Champion Name</th>
                                            <th>Champion ID</th>
                                            <th>City</th>
                                            <th>Status (Active/De-Active)</th>
                                            <th>Subscription End date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="filteredChampions && filteredChampions.length > 0; else noChampions">
                                            <tr *ngFor="let champion of filteredChampions">
                                                <td>{{ champion.championName || 'Not Provided' }}</td>
                                                <td>
                                                    <input type="text" [value]="champion.championCode || 'Not Provided'"
                                                        readonly class="form-control" style="cursor: pointer;"
                                                        (click)="selectText($event)">
                                                </td>
                                                <td>{{ champion.cityName || 'Not Provided' }}</td>
                                                <td>{{ champion.status === '1' ? 'Active' : 'Inactive' }}</td>
                                                <td>{{ champion.endDate ? (champion.endDate | date:'mediumDate') : 'No End Date' }}</td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <ng-template #noChampions>
                                    <tr>
                                        <td colspan="4" class="text-center">
                                            <div class="py-5">
                                                <i class="bi bi-star text-muted" style="font-size: 4rem;"></i>
                                                <h4 class="mt-3 text-muted">No Referred  Champions</h4>
                                                <p class="text-muted mb-4">Please refer and earn rewards by onboarding new  champions.</p>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    
                    <div class="container-fluid">
                        <!-- First table remains the same -->
                        <div class="card shadow-sm mb-4">
                            <div class="card-header bg-warning">
                                <h5 class="section-header">SELLERS ONBOARDED (CHAMPION WISE)</h5>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <div style="overflow-x: auto;">
                                        <table class="table table-bordered table-hover">
                                            <thead class="bg-light">
                                                <tr>
                                                    <th>SELLER NAME</th>
                                                    <th>SELLER ID</th>
                                                    <th>Subscription End Date</th>
                                                    <th>City</th>
                                                    <th>Category Name/Type</th>
                                                    <th>ON-BOARDED BY (CHAMPION NAME)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngIf="!sellers || sellers.length === 0">
                                                    <td colspan="6" class="text-center py-4">
                                                        <h5 class="text-muted mb-2">No Sellers Onboarded Yet</h5>
                                                        <p class="text-muted small">New sellers will appear here once they are onboarded.</p>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let seller of sellers">
                                                    <td>{{ seller.userFullName || 'Not Provided' }}</td>
                                                    <td>{{ seller.id || 'Not Provided' }}</td>
                                                    <td>{{ seller.candidOfferSubscriptionEndDate ? (seller.candidOfferSubscriptionEndDate | date:'yyyy-MM-dd') : 'No End Date' }}</td>
                                                    <td>{{ seller.userAddressCity || 'Not Provided' }}</td>
                                                    <td>
                                                        <ul class="list-unstyled m-0">
                                                            <li *ngFor="let category of (seller.selectedCatsList || [])">
                                                                {{ category.catName || 'Unknown Category' }}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td>{{ seller.championName || 'Not Provided' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <!-- Modified Offers Purchased Table -->
                        <div class="card shadow-sm mb-4">
                            <div class="card-header bg-warning d-flex justify-content-between align-items-center">
                                <h5 class="section-header">OFFERS PURCHASED (SELLERS WISE)</h5>
                                <!-- <div class="total-summary">
                                    <span class="badge bg-primary">Total Offers: {{ totalOffersPurchased }}</span>
                                </div> -->
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover">
                                        <thead class="bg-light">
                                            <tr>
                                                <th>Seller Name</th>
                                                <th>Seller ID</th>
                                                <th>Champion ID</th>
                                                <th>City</th>
                                                <th>Offer Plan Name</th>
                                                <th>Plan Validity</th>
                                                <th>Amount (₹)</th>
                                                <th>Total Offers</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngIf="!encashmentTableData || encashmentTableData.length === 0">
                                                <td colspan="8" class="text-center py-4">
                                                    <h5 class="text-muted mb-2">No Offers Purchased</h5>
                                                    <p class="text-muted small">There are currently no offers purchased by sellers</p>
                                                </td>
                                            </tr>
                                            <ng-container *ngFor="let data of encashmentTableData">
                                                <tr *ngFor="let plan of data.plans">
                                                    <td>{{ data.vendorName }}</td>
                                                    <td>{{ data.vendorId }}</td>
                                                    <td>{{ data.championId }}</td>
                                                    <td>{{ data.vendorCity }}</td>
                                                    <td>{{ plan.planName }}</td>
                                                    <td>{{ plan.planEndDate ? (plan.planEndDate | date:'mediumDate') : 'No End Date' }}</td>
                                                    <td>{{ plan.planCost | number:'1.0-0' }}</td>
                                                    <td class="text-center">
                                                        <span class="badge bg-primary">{{ plan.offerCount }}</span>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Update the table header to include Champion Name column -->
                  
                </div>



                <!-- Referral Section -->
                <!-- <div *ngIf="['SUB ADMIN', 'ADMIN', 'SUPER ADMIN', 'DEVELOPER'].includes(groupName)" class="row my-4">
  <div class="col-md-6">
      <div class="modern-chart-container">
          <div class="chart-header">
              <h3 class="section-header">
                  <i class="bi bi-people"></i>
                  Referred By Champions
              </h3>
          </div>
          <div class="chart-content">
              <div class="table-responsive">
                  <table class="modern-table">
                      <thead>
                          <tr>
                              <th><i class="bi bi-person"></i> Vendor Name</th>
                              <th><i class="bi bi-trophy"></i> Champion Name</th>
                              <th><i class="bi bi-geo-alt"></i> City</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let vendor of referredChampions">
                              <td>{{vendor.userFullName}}</td>
                              <td>{{vendor.championName}}</td>
                              <td>{{vendor.cityName}}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  </div>
  <div class="col-md-6">
      <div class="modern-chart-container">
          <div class="chart-header">
              <h3 class="section-header">
                  <i class="bi bi-diagram-3"></i>
                  Referred By Vendors
              </h3>
          </div>
          <div class="chart-content">
              <div class="table-responsive">
                  <table class="modern-table">
                      <thead>
                          <tr>
                              <th><i class="bi bi-person"></i> Vendor Name</th>
                              <th><i class="bi bi-person-plus"></i> Referred by Vendor</th>
                              <th><i class="bi bi-geo-alt"></i> City</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let vendor of referredVendors">
                              <td>{{vendor.userFullName}}</td>
                              <td>{{vendor.referredByVendorName}}</td>
                              <td>{{vendor.cityName}}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  </div>
</div> -->




                <!-- <div class="col-md-6" *ngIf="isDrillDownOffersChart">
      <div class="modern-chart-container">
          <div class="chart-header">
              <h3 class="section-header">
                  <i class="bi bi-pie-chart"></i>
                  Offer Status Distribution
              </h3>
          </div>
          <div class="chart-content">
              <div echarts [options]="pieChartData" class="modern-chart"></div>
          </div>
      </div>
  </div> -->


                <!-- Target Charts -->
                <!-- <div class="row mt-4">
  <div class="col-md-12">
      <div class="modern-chart-container">
          <div class="chart-header">
              <h3 class="section-header">
                  <i class="bi bi-bar-chart-line"></i>
                  User Targets Achieved & Balance
              </h3>
              <div class="chart-legend">
                  <span class="legend-item">
                      <i class="bi bi-circle-fill text-success"></i>
                      Achieved
                  </span>
                  <span class="legend-item">
                      <i class="bi bi-circle-fill text-warning"></i>
                      Pending
                  </span>
              </div>
          </div>
          <div class="chart-content">
              <div echarts [options]="targetChartData" class="modern-chart"></div>
          </div>
      </div>
  </div>
</div> -->

                <style>
                    .modern-chart-container {
                        background: white;
                        border-radius: 16px;
                        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
                        margin-bottom: 1.5rem;
                        transition: all 0.3s ease;
                        overflow: hidden;
                    }

                    .modern-chart-container:hover {
                        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
                        transform: translateY(-2px);
                    }

                    .chart-header {
                        padding: 1.25rem;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .chart-content {
                        padding: 1.5rem;
                        min-height: 300px;
                    }

                    .modern-chart {
                        height: 300px;
                        width: 100%;
                    }

                    .status-badge {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                        /* Space between icon and text */
                        padding: 10px 20px;
                        border-radius: 20px;
                        /* Rounded edges for badge */
                        font-weight: 600;
                        /* Bold text */
                        font-size: 14px;
                        /* Font size for readability */
                        text-transform: uppercase;
                        white-space: nowrap;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                        /* Subtle shadow for depth */
                    }

                    .status-badge.active {
                        background-color: #28a745;
                        /* Green background for Active */
                        color: #ffffff;
                        /* White text */
                    }

                    .status-badge.inactive {
                        background-color: #dc3545;
                        /* Red background for Inactive */
                        color: #ffffff;
                        /* White text */
                    }

                    .status-icon {
                        font-size: 16px;
                        /* Icon size */
                    }

                    .chart-action-btn {
                        background: none;
                        border: none;
                        color: #64748b;
                        padding: 0.5rem;
                        border-radius: 8px;
                        cursor: pointer;
                        transition: all 0.2s ease;
                    }

                    .chart-action-btn:hover {
                        background: #f1f5f9;
                        color: #0f172a;
                    }

                    .chart-legend {
                        display: flex;
                        gap: 1rem;
                        align-items: center;
                    }

                    .legend-item {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        font-size: 0.875rem;
                        color: #64748b;
                    }

                    .legend-item i {
                        font-size: 0.75rem;
                    }

                    .text-success {
                        color: #22c55e;
                    }

                    .text-warning {
                        color: #f59e0b;
                    }

                    .qr-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 2rem;
                        background: #f8fafc;
                        border-radius: 12px;
                    }

                    .modern-table {
                        width: 100%;
                        border-collapse: separate;
                        border-spacing: 0;
                    }

                    .modern-table th {
                        background: #f8fafc;
                        color: #1e293b;
                        font-weight: 600;
                        padding: 1rem;
                        font-size: 0.875rem;
                        text-transform: uppercase;
                        letter-spacing: 0.05em;
                    }

                    .modern-table td {
                        padding: 1rem;
                        border-bottom: 1px solid #e2e8f0;
                    }

                    .modern-table tr:last-child td {
                        border-bottom: none;
                    }

                    .modern-table tr:hover td {
                        background: #f1f5f9;
                    }

                    @media (max-width: 768px) {
                        .chart-header {
                            flex-direction: column;
                            gap: 1rem;
                        }

                        .chart-content {
                            padding: 1rem;
                        }

                        .modern-chart {
                            height: 250px;
                        }
                    }
                </style>
                <!-- Kit Issues -->
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3 class="section-header">
                                <i class="bi bi-box-seam"></i>
                                Kit Issues
                            </h3>
                            <button (click)="exportAsXLSXKitIssues()" class="btn-export btn-export-kit">
                                <i class="bi bi-download"></i>
                                Export Kit Data
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <ng-container *ngIf="excel7 && excel7.length > 0; else noData">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th><i class="bi bi-upc-scan"></i> Reference No</th>
                                            <th><i class="bi bi-calendar"></i> Issue Date</th>
                                            <th><i class="bi bi-person"></i> Issued UserID</th>
                                            <th><i class="bi bi-box"></i> Item</th>
                                            <th><i class="bi bi-rulers"></i> UOM</th>
                                            <th><i class="bi bi-hash"></i> Count</th>
                                            <th><i class="bi bi-building"></i> Channel</th>
                                            <th><i class="bi bi-currency-rupee"></i> Amount</th>
                                            <th><i class="bi bi-geo-alt"></i> City</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of excel7">
                                            <td>{{item.kitIssueReferenceNo}}</td>
                                            <td>{{item.kitIssueDate | date:'dd MMM yyyy'}}</td>
                                            <td>{{item.kitIssueUserId}}</td>
                                            <td>{{item.kitIssueItemName}}</td>
                                            <td>{{item.kitIssueItemUomName}}</td>
                                            <td>{{item.kitIssueItemCount}}</td>
                                            <td>{{item.kitIssueItemCompanyName}}</td>
                                            <td class="text-amount">₹{{item.kitIssueKitsAmount | number:'1.2-2'}}</td>
                                            <td>{{item.cityName}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                            <ng-template #noData>
                                <div class="text-center py-5">
                                    <i class="bi bi-inbox text-muted" style="font-size: 3rem;"></i>
                                    <h4 class="mt-3 text-muted">No Kit Issues Found</h4>
                                    <p class="text-muted">There are currently no kit issues to display.</p>
                                    <!-- <button class="btn btn-outline-primary mt-2">
                                        <i class="bi bi-plus-circle me-2"></i>Create New Kit Issue
                                    </button> -->
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <!-- Weekly Transactions -->
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3 class="section-header">
                                <i class="bi bi-calendar-week"></i>
                                Weekly Transactions
                            </h3>
                            <button (click)="exportAsXLSXOrder()" class="btn-export btn-export-champion">
                                <i class="bi bi-download"></i>
                                Export Transactions
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <ng-container *ngIf="excel6 && excel6.length > 0; else noTransactions">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th><i class="bi bi-hash"></i> Batch ID</th>
                                            <th><i class="bi bi-send"></i> Dispatch ID</th>
                                            <th><i class="bi bi-graph-up"></i> Start Amount</th>
                                            <th><i class="bi bi-graph-down"></i> End Amount</th>
                                            <th><i class="bi bi-calendar"></i> Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of excel6">
                                            <td><span class="badge bg-primary">{{item.wklyBatchId}}</span></td>
                                            <td>{{item.txnRemitDispatchId}}</td>
                                            <td class="text-amount text-success">₹{{item.ledgerStartAmount |
                                                number:'1.2-2'}}</td>
                                            <td class="text-amount text-danger">₹{{item.ledgerEndAmount |
                                                number:'1.2-2'}}
                                            </td>
                                            <td>{{item.txnRemitDate | date:'dd MMM yyyy'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                            <ng-template #noTransactions>
                                <div class="text-center py-5">
                                    <i class="bi bi-wallet2 text-muted" style="font-size: 4rem;"></i>
                                    <h4 class="mt-3 text-muted">No Transactions Found</h4>
                                    <p class="text-muted">There are no weekly transactions to display at this time.</p>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <!-- Rewards and Earnings -->
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3 class="section-header">
                                <i class="bi bi-cash-coin me-2"></i>
                                REWARDS/EARNINGS
                            </h3>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-12">
                                <div class="alert alert-info">
                                    <i class="bi bi-info-circle-fill me-2"></i>
                                    <strong>FIXED AND ONE TIME EARNINGS</strong>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped table-hover">
                                <tbody>
                                    <tr>
                                        <td class="fw-bold">
                                            <i class="bi bi-star-fill text-warning me-2"></i>
                                            <ng-container *ngIf="userGroupName === 'CHAMPION'">
                                                EARNINGS BY MAKING PRIME CUSTOMERS
                                            </ng-container>
                                            <ng-container *ngIf="userGroupName === 'SUPER CHAMPION'">
                                                EARNINGS BY REFERING SUPER CHAMPIONS
                                            </ng-container>
                                        </td>
                                        <td class="text-end text-success">₹{{earningsData.superChampionEarnings}}</td>
                                    </tr>
                                    <tr>
                                        <td class="fw-bold">
                                            <i class="bi bi-person-plus-fill text-primary me-2"></i>
                                            EARNINGS BY CHAMPIONS ONBOARDING
                                        </td>
                                        <td class="text-end text-success">₹{{earningsData.championEarnings}}</td>
                                    </tr>
                                    <tr class="table-light">
                                        <td class="fw-bold fs-5">
                                            <i class="bi bi-calculator me-2"></i>
                                            TOTAL (A)
                                        </td>
                                        <td class="text-end fw-bold fs-5 text-success">
                                            ₹{{earningsData.totalEarnings}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="analytics-section mt-4">
                    <div class="analytics-card">
                        <div class="analytics-header">
                            <div class="header-content">
                                <div class="title-section">
                                    <h3>
                                        <i class="bi bi-graph-up-arrow"></i>
                                        {{ userGroupName === 'CHAMPION' ? 'SELLER EARNINGS DASHBOARD' : 'CHAMPION EARNINGS DASHBOARD' }}
                                    </h3>
                                    <p class="subtitle">
                                        {{ userGroupName === 'CHAMPION' ? 'Track your earnings from seller activities' : 'Monitor champion performance and earnings' }}
                                    </p>
                                </div>
                                
                                <!-- Only show for admin roles -->
                                <div class="commission-control" *ngIf="['DEVELOPER', 'SUPER ADMIN', 'ADMIN'].includes(userGroupName)">
                                    <div class="commission-settings">
                                        <h4 class="settings-title">Commission Rate Settings</h4>
                                        <div class="rates-container">
                                            <!-- Champion Rate -->
                                            <div class="rate-control">
                                                <label>Champion Commission Rate</label>
                                                <div class="input-group">
                                                    <input 
                                                        type="number" 
                                                        class="form-control custom-input" 
                                                        [value]="analytics.championCommissionRate" 
                                                        #championInput
                                                        min="0"
                                                        max="100"
                                                        placeholder="Champion Rate"
                                                    >
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>

                                            <!-- Super Champion Rate -->
                                            <div class="rate-control">
                                                <label>Super Champion Commission Rate</label>
                                                <div class="input-group">
                                                    <input 
                                                        type="number" 
                                                        class="form-control custom-input" 
                                                        [value]="analytics.superChampionCommissionRate" 
                                                        #superChampionInput
                                                        min="0"
                                                        max="100"
                                                        placeholder="Super Champion Rate"
                                                    >
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>

                                            <!-- Update Button -->
                                            <button 
                                                class="btn btn-update" 
                                                (click)="updateCommissionRates(+championInput.value, +superChampionInput.value)"
                                            >
                                                <i class="bi bi-check2-circle"></i>
                                                Update Rates
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="analytics-body">
                            <div class="stats-grid">
                                <!-- Total Members Card -->
                                <div class="stat-card primary">
                                    <div class="stat-icon">
                                        <i class="bi bi-people-fill"></i>
                                    </div>
                                    <div class="stat-details">
                                        <h4>{{ userGroupName === 'CHAMPION' ? 'Total Sellers' : 'Total Champions(Sellers)' }}</h4>
                                        <p class="stat-value">{{analytics.totalSellers}}</p>
                                    </div>
                                </div>

                                <!-- Total Revenue Card -->
                                <div class="stat-card success">
                                    <div class="stat-icon">
                                        <i class="bi bi-currency-rupee"></i>
                                    </div>
                                    <div class="stat-details">
                                        <h4>Total Revenue</h4>
                                        <p class="stat-value">₹{{analytics.totalRevenue | number:'1.2-2'}}</p>
                                    </div>
                                </div>

                                <!-- Commission Rate Card -->
                                <div class="stat-card info">
                                    <div class="stat-icon">
                                        <i class="bi bi-percent"></i>
                                    </div>
                                    <div class="stat-details">
                                        <h4>Commission Rate</h4>
                                        <p class="stat-value">{{analytics.commissionPercentage}}%</p>
                                    </div>
                                </div>

                                <!-- Total Commission Card -->
                                <div class="stat-card warning">
                                    <div class="stat-icon">
                                        <i class="bi bi-wallet2"></i>
                                    </div>
                                    <div class="stat-details">
                                        <h4>Total Commission</h4>
                                        <p class="stat-value">₹{{analytics.commissionAmount | number:'1.2-2'}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="revenue-details">
                                <h4 class="details-title">Revenue Breakdown</h4>
                                <div class="details-table">
                                    <div class="table-row">
                                        <div class="row-label">{{ userGroupName === 'CHAMPION' ? 'Sellers Onboarding' : 'Sellers Onboarding' }}</div>
                                        <div class="row-value">₹{{analytics.totalSubscriptionAmount | number:'1.2-2'}}</div>
                                    </div>
                                    <div class="table-row">
                                        <div class="row-label">Offers Purchased</div>
                                        <div class="row-value">₹{{analytics.totalOfferPurchases | number:'1.2-2'}}</div>
                                    </div>
                                    <div class="table-row total">
                                        <div class="row-label">TOTAL (B)</div>
                                        <div class="row-value highlight">₹{{analytics.commissionAmount | number:'1.2-2'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
                  
                <!-- <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3 class="section-header">
                                <i class="bi bi-gift"></i>
                                Offer Upload Packages
                            </h3>
                            <button (click)="exportAsXLSXOfferUpload()" class="btn-export btn-export-kit">
                                <i class="bi bi-download"></i>
                                Export Offers
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <ng-container *ngIf="excel1 && excel1.length > 0; else noOffers">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th><i class="bi bi-gift"></i> Offer Name</th>
                                            <th><i class="bi bi-currency-rupee"></i> Cost</th>
                                            <th><i class="bi bi-circle"></i> Status</th>
                                            <th><i class="bi bi-geo-alt"></i> City</th>
                                            <th><i class="bi bi-hash"></i> Offer Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of excel1">
                                            <td>{{item.planName}}</td>
                                            <td class="text-amount">₹{{item.cost | number:'1.2-2'}}</td>
                                            <td>
                                                <span
                                                    [class]="'badge ' + (item.planStatus === 'Active' ? 'bg-success' : 'bg-danger')">
                                                    {{item.planStatus}}
                                                </span>
                                            </td>
                                            <td>{{item.city}}</td>
                                            <td>{{item.offerCount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                            <ng-template #noOffers>
                                <div class="text-center py-5">
                                    <i class="bi bi-gift text-muted" style="font-size: 4rem;"></i>
                                    <h4 class="mt-3 text-muted">No Offers Available</h4>
                                    <p class="text-muted mb-4">There are no offer packages uploaded at this time.</p>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div> -->
            </div>


            <!-- Add Bootstrap Icons -->
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css">

            <!-- After the Rewards and Earnings card -->
            <div class="card mb-4">
                <div class="card-header bg-warning">
                    <div class="d-flex justify-content-between align-items-center">
                        <h3 class="section-header">
                            <i class="bi bi-calculator-fill me-2"></i>
                            NET EARNINGS
                        </h3>
                    </div>
                </div>
                <div class="card-body">
                    <!-- Net Earnings Summary -->
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead class="table-light">
                                <tr>
                                    <th colspan="2" class="text-center">NET EARNINGS = (A) + (B)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Total Fixed Earnings (A)</td>
                                    <td class="text-end">₹{{netEarnings.totalEarnings | number:'1.2-2'}}</td>
                                </tr>
                                <tr>
                                    <td>Total Commission Amount (B)</td>
                                    <td class="text-end">₹{{netEarnings.commissionAmount | number:'1.2-2'}}</td>
                                </tr>
                                <tr class="table-warning fw-bold">
                                    <td>NET EARNINGS (A) + (B)</td>
                                    <td class="text-end">₹{{netEarnings.total | number:'1.2-2'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Ledger/Passbook -->
                    <!-- <div class="mt-4">
                        <h5 class="mb-3">LEDGER/PASSBOOK</h5>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead class="table-light">
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Date</th>
                                        <th>NET EARNINGS = (A) + (B)<br>(Debit) Amount</th>
                                        <th>Payment Details (Credit)<br>(RTGS/NEFT/UTR No./Bank) Amount</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    If you have transaction data, you can loop through it here -->
                                    <!-- <tr *ngIf="excel6 && excel6.length > 0">
                                        <td>1</td>
                                        <td>{{excel6[0].txnRemitDate | date:'dd/MM/yyyy'}}</td>
                                        <td class="text-end">₹{{netEarnings.total | number:'1.2-2'}}</td>
                                        <td>{{excel6[0].txnRemitDispatchId || 'Pending'}}</td>
                                        <td class="text-end">₹{{netEarnings.total | number:'1.2-2'}}</td>
                                    </tr> -->
                                    <!-- If no transactions
                                    <tr *ngIf="!excel6 || excel6.length === 0">
                                        <td colspan="5" class="text-center">No transaction records available</td>
                                    </tr> -->
                                <!-- </tbody>
                            </table>
                        </div> -->
                    </div>
                </div>
            </div>